<template>
  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Urn Stock Movement Report
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="urnStockMovementReportForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="generateReport">
        <b-card
          header-tag="header"
        >
          <b-row>
            <b-col
              cols="md-12"
            >
              <validation-provider
                #default="{ errors }"
                name="Urn"
                vid="urn"
                rules="required"
              >
                <b-form-group
                  label="By"
                  label-for="urn-name"
                  label-cols-md="1"
                  :state="(errors.length > 0 || urnValidation) ? false : null"
                >
                  <b-form-tags
                    v-model="urns"
                    size="lg"
                    add-on-change
                    no-outer-focus
                  >
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1 user__lists"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="danger"
                            @remove="removeTag(tag)"
                          >
                            {{ resolveItemName(tag) }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                        @input="updateOptionData"
                      >
                        <template v-slot:first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option
                            disabled
                            value=""
                          >
                            Select urns...
                          </option>
                        </template>
                        <option
                          v-for="(opt, ind) in urnOptions"
                          :key="ind"
                          :value="opt._id"
                        >
                          {{ opt.name }}
                        </option>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="urnValidation"
                    class="text-danger"
                  >
                    {{ urnError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="md-12"
            >
              <validation-provider
                #default="{ errors }"
                name="Date"
                vid="date"
                rules="required"
                class="date__picker_block white-bg-dp mr-2"
              >
                <b-form-group
                  label="Date Range"
                  label-cols-md="1"
                  :state="(errors.length > 0 || dateValidation) ? false : null"
                >
                  <b-input-group>
                    <flat-pickr
                      id="main-date"
                      v-model="dateRange"
                      class="form-control"
                      placeholder="Select a date range"
                      :config="flatPickrConfig"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="CalendarIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="dateValidation"
                    class="text-danger"
                  >
                    {{ dateError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-6" />
            <b-col cols="md-6">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="float-right"
                :disabled="invalid"
                @click="generateReport"
              >
                <span class="align-middle">Generate Report</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

    <div v-if="showUrnReportCard">
      <b-card
        v-for="(urnData, key) in urnReportData"
        :key="key"
        header-tag="header"
        class="chart-card report__card"
      >
        <template
          v-if="key == 0"
          #header
        >
          <h3 class="align-middle mr-auto">
            Urn Stock Movement ({{ dateRangeValue }})
          </h3>
          <b-button
            type="button"
            variant="flat-primary"
            class="float-right"
            @click="downloadExcel()"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
              size="20"
            />
            <span class="">Download Excel</span>
          </b-button>
        </template>
        <b-row>
          <b-col
            cols="12"
            class=""
          >
            <b-table
              :id="'urnTable' + key"
              class="position-relative"
              :items="urnData.transactions"
              responsive
              :fields="tableColumns"
              show-empty
              no-sort-reset
              :sort-by="sortBy"
              :sort-desc="sortDirDesc"
              sort-compare-locale="zh"
              empty-text="No matching records found"
            >
              <template #thead-top>
                <b-tr>
                  <b-th
                    colspan="15"
                    class="custom-thead-color"
                  >
                    <div class="d-flex">
                      <b-img
                        class="img-preview-block"
                        :src="getItemImage(urnData.id)"
                      />
                      <div
                        class="ml-1 d-flex align-items-center"
                      >
                        <span class="custom-thead-item-title">{{ resolveItemName(urnData.id) }}</span>
                      </div>
                    </div>
                  </b-th>
                </b-tr>
              </template>
              <template #cell(transactionStringID)="data">
                <div class="text-nowrap">
                  <span class="text-bold-black">{{ data.item.transactionStringID || '-' }}</span>
                </div>
              </template>
              <template #cell(requestStringID)="data">
                <div class="text-nowrap">
                  <span class="text-bold-black">{{ data.item.requestItem ? (data.item.requestItem.requestStringID || '-') : '-' }}</span>
                </div>
              </template>
              <template #cell(requestFileNo)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.requestItem ? (data.item.requestItem.requestFileNo || '-') : '-' }}</span>
                </div>
              </template>
              <template #cell(createdAt)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.createdAt ? dateFormat(data.item.createdAt) : '-' }}</span>
                </div>
              </template>
              <template #cell(createdBy)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.requestItem ? data.item.requestItem.request.createdBy.name : data.item.createdBy.name }}</span>
                </div>
              </template>
              <template #cell(inQuantity)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.stockInQuantity }}</span>
                </div>
              </template>
              <template #cell(outQuantity)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.stockOutQuantity }}</span>
                </div>
              </template>
              <template #cell(adjustedQuantity)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.stockAdjustedQuantity }}</span>
                </div>
              </template>
              <template #cell(returnedQuantity)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.returnedQuantity }}</span>
                </div>
              </template>
              <template #cell(stockQty)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.stockQuantity || '-' }}</span>
                </div>
              </template>
              <template #cell(deceasedAngling)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.requestItem ? (data.item.requestItem.request.serviceForm.deceasedAngling || '-') : '-' }}</span>
                </div>
              </template>
              <template #cell(nmgQty)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.requestItem ? (data.item.requestItem.quantity[0] ? data.item.requestItem.quantity[0] : '-') : '-' }}</span>
                </div>
              </template>
              <template #cell(mplQty)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.requestItem ? (data.item.requestItem.quantity[1] ? data.item.requestItem.quantity[1] : '-') : '-' }}</span>
                </div>
              </template>
              <template #cell(remarks)="data">
                <div class="">
                  <span>{{ data.item.transaction.remarks ? data.item.transaction.remarks : '-' }}</span>
                </div>
              </template>
              <template #cell(fileNo)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.requestItem ? (data.item.requestItem.fileNo) : '-' }}</span>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BButton, BFormTags, BFormTag, BFormSelect, BFormGroup,
  BInputGroup, BInputGroupAppend, BTable, BImg, BTr, BTh,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-unresolved
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { writeFile, utils } from 'xlsx'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormTags,
    BFormTag,
    BFormSelect,
    BFormGroup,
    BInputGroup,
    BTable,
    BInputGroupAppend,
    BImg,
    BTr,
    BTh,

    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      showUrnReportCard: false,
      urns: [],
      urnError: 'Valid urn is required',
      urnValidation: false,
      dateError: 'Valid date is required',
      dateRangeData: '',
      dateRange: '',
      dateValidation: false,
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, disableMobile: true,
      },
      submittedDate: '',
      urnOptions: [
        {
          name: 'Select All',
          _id: 'all',
        },
      ],
      onlyUrnOptions: [],
      urnReportData: [],
      tableColumns: [
        {
          key: 'transactionStringID',
          label: 'id',
          sortable: false,
        },
        {
          key: 'requestStringID',
          label: 'ir no.',
          sortable: false,
        },
        {
          key: 'requestFileNo',
          label: 'file no.',
          sortable: false,
        },
        {
          key: 'createdAt',
          label: 'txn date',
          sortable: false,
        },
        {
          key: 'createdBy',
          label: 'issue to',
          sortable: false,
        },
        {
          key: 'inQuantity',
          label: 'Stock in qty',
          sortable: false,
        },
        {
          key: 'outQuantity',
          label: 'Stock out Qty',
          sortable: false,
        },
        {
          key: 'adjustedQuantity',
          label: 'Adj qty',
          sortable: false,
        },
        {
          key: 'returnedQuantity',
          label: 'item returns',
          sortable: false,
        },
        {
          key: 'stockQty',
          label: 'stock qty',
          sortable: false,
        },
        {
          key: 'deceasedAngling',
          label: 'lot no.',
          sortable: false,
        },
        {
          key: 'nmgQty',
          label: 'nmg (foc)',
          sortable: false,
        },
        {
          key: 'mplQty',
          label: 'mpl (buy)',
          sortable: false,
        },
        {
          key: 'remarks',
          label: 'remarks',
          sortable: false,
        },
        {
          key: 'fileNo',
          label: 'item file no.',
          sortable: false,
        },
      ],
      urnTableData: [],
      sortBy: 'transactionStringID',
      sortDirDesc: false,
      // validation rules
      required,
    }
  },

  computed: {
    urnsComputed() {
      return this.submittedUrns
    },
    dateRangeValue() {
      return this.submittedDate
    },
  },
  beforeMount() {
    this.$http.get('inventory/reports/urn-stock-movement/extra-options')
      .then(response => {
        this.onlyUrnOptions = response.data.urnOptions
        this.urnOptions = this.urnOptions.concat(response.data.urnOptions ?? [])
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale, dataKey) {
      let a = ''
      let b = ''
      if (key === 'requestStringID') {
        a = aRow.requestStringID
        b = bRow.requestStringID
      } else if (key === 'requestFileNo') {
        a = aRow.requestFileNo
        b = bRow.requestFileNo
      } else if (key === 'processDate') {
        a = aRow.request.processDate
        b = bRow.request.processDate
      } else if (key === 'createdBy') {
        a = aRow.request.createdBy.name
        b = bRow.request.createdBy.name
      } else if (key === 'totalQuantity') {
        a = aRow.totalQuantity
        b = bRow.totalQuantity
      } else if (key === 'returnedQuantity') {
        a = aRow.returnedQuantity
        b = bRow.returnedQuantity
      } else if (key === 'deceasedAngling') {
        a = aRow.request.serviceForm.deceasedAngling
        b = bRow.request.serviceForm.deceasedAngling
      } else if (key === 'nmgQty') {
        // eslint-disable-next-line prefer-destructuring
        a = aRow.quantity[0]
        // eslint-disable-next-line prefer-destructuring
        b = bRow.quantity[0]
      } else if (key === 'mplQty') {
        // eslint-disable-next-line prefer-destructuring
        a = aRow.quantity[1]
        // eslint-disable-next-line prefer-destructuring
        b = bRow.quantity[1]
      } else if (key === 'remarks') {
        a = aRow.request.remarks
        b = bRow.request.remarks
      } else if (key === 'fileNo') {
        a = aRow.fileNo
        b = bRow.fileNo
      } else {
        a = this.resolveStockQuantity(aRow.item, aRow.request._id, dataKey)
        b = this.resolveStockQuantity(bRow.item, bRow.request._id, dataKey)
      }

      if (
        (typeof a === 'number' && typeof b === 'number')
        || (a instanceof Date && b instanceof Date)
      ) {
        // eslint-disable-next-line no-nested-ternary
        return a < b ? -1 : a > b ? 1 : 0
      }
      return a.localeCompare(b, compareLocale, compareOptions)
    },
    toString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      }
      if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => this.toString(value[key]))
          .join(' ')
      }
      return String(value)
    },
    resolveItemName(item) {
      const found = this.urnOptions.find(o => o._id === item)
      if (found) {
        return found.name
      }
      return ''
    },
    getItemImage(item) {
      const found = this.urnOptions.find(o => o._id === item)
      if (found) {
        return found.image
      }
      return this.defaultImage
    },
    resolveStockQuantity(item, id, key) {
      const found = this.urnReportData[key].transactions.find(trnData => trnData.item === item && trnData.transaction.irReference === id)
      if (found) {
        return found.stockAfter
      }
      return '-'
    },
    updateOptionData() {
      if (this.urns.includes('all')) {
        this.urns = this.urns.filter(el => el === 'all')
      }
    },
    downloadExcel() {
      if (this.urnReportData.length) {
        const worksheet = utils.aoa_to_sheet([
          ['', `Urn Stock Movement (${this.dateRangeValue})`],
          // ['ID', 'IR No.', 'File No.', 'Transaction Date', 'Issue To', 'Stock in qty', 'Stock out Qty', 'Adj qty', 'Item Returns', 'Stock Qty', 'Lot No.', 'NMG (FOC)', 'MPL (Buy)', 'Remarks', 'Item File No.'],
        ])
        this.urnReportData.forEach(urnData => {
          if (urnData.transactions.length) {
            const itemObject = [
              {
                name: this.resolveItemName(urnData.id),
                col1: 'ID',
                col2: 'IR No.',
                col3: 'File No.',
                col4: 'Transaction Date',
                col5: 'Issue To',
                col6: 'Stock In Qty',
                col7: 'Stock Out Qty',
                col8: 'Adjustment Qty',
                col9: 'Item Returns',
                col10: 'Stock Qty',
                col11: 'Lot No.',
                col13: 'NMG (FOC)',
                col14: 'MPL (Buy)',
                col15: 'Remarks',
                col16: 'Item File No.',
              },
            ]
            utils.sheet_add_json(worksheet, itemObject, { skipHeader: true, origin: -1 })
            const data = []
            urnData.transactions.forEach(obj => {
              data.push({
                name: '',
                transactionStringID: obj.transactionStringID,
                requestStringID: obj.requestItem ? (obj.requestItem.requestStringID || '-') : '-',
                requestFileNo: obj.requestItem ? (obj.requestItem.requestFileNo || '-') : '-',
                processDate: obj.createdAt ? this.dateFormat(obj.createdAt) : '-',
                createdBy: obj.requestItem ? obj.requestItem.request.createdBy.name : obj.createdBy.name,
                inQuantity: obj.stockInQuantity,
                outQuantity: obj.stockOutQuantity,
                adjustedQuantity: obj.stockAdjustedQuantity,
                returnedQuantity: obj.returnedQuantity,
                stockQuantity: obj.stockQuantity || '-',
                deceasedAngling: obj.requestItem ? (obj.requestItem.request.serviceForm.deceasedAngling || '-') : '-',
                // eslint-disable-next-line no-nested-ternary
                nmgQty: obj.requestItem ? (obj.requestItem.quantity[0] ? obj.requestItem.quantity[0] : '-') : '-',
                // eslint-disable-next-line no-nested-ternary
                mplQty: obj.requestItem ? (obj.requestItem.quantity[1] ? obj.requestItem.quantity[1] : '-') : '-',
                remarks: obj.transaction.remarks ? obj.transaction.remarks : '-',
                fileNo: obj.requestItem ? (obj.requestItem.fileNo) : '-',
              })
            })
            utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: -1 })
          }
        })
        worksheet['!cols'] = [{ wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 30 }, { wch: 15 }]
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, 'Urn-Reports')
        writeFile(workbook, `Urn Stock Movement Report - ${this.dateRangeValue}.xlsx`, { compression: true })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Result not found! Please change the input field value and try again.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    generateReport() {
      this.$refs.urnStockMovementReportForm.validate().then(success => {
        if (success) {
          this.submittedDate = this.dateRange
          const formData = new FormData()

          if (this.urns.includes('all')) {
            formData.append('urns', JSON.stringify(this.onlyUrnOptions.map(urn => urn._id)))
          } else {
            formData.append('urns', JSON.stringify(this.urns))
          }
          // formData.append('urns', JSON.stringify(this.urns))
          formData.append('date', JSON.stringify(this.dateRange))
          this.$http.post('inventory/reports/generate/urn-stock-movement', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.reportData) {
                this.urnReportData = response.data.reportData
                this.sortDirDesc = false
                this.showUrnReportCard = true
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'BellIcon',
                    variant: 'success',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'urn') {
                    this.urnError = validationError.msg
                    this.urnValidation = true
                  } else if (validationError.param === 'dateRange') {
                    this.dateRangeError = validationError.msg
                    this.dateRangeValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
<style scoped>
.img-preview-block{
      box-sizing: border-box;
      width: 38px;
      height: 38px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
.custom-thead-color{
   background: #4B4B4B !important;
   border: none !important;
}

.custom-thead-item-title{
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}
</style>
